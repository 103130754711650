import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';

Vue.use(VueRouter);
Vue.use(Meta);

const routes = [
  {
    path: '/',
    name: 'SplashScreen',
    component: () => import('../views/SplashScreen.vue'),
  },
  {
    path: '/auth',
    name: 'LoginSelector',
    component: () => import('../views/LoginSelector.vue'),
  },
  {
    path: '/offline',
    name: 'OfflineMode',
    component: () => import('../views/OfflineMode.vue'),
  },
  {
    path: '/auth',
    name: 'LoginPages',
    component: () => import('../views/LoginPages.vue'),
    children: [
      {
        path: 'authorization',
        name: 'Authorization',
        component: () => import('../views/Authorization.vue'),
      },
      {
        path: 'email',
        name: 'LoginEmail',
        component: () => import('../views/LoginEmail.vue'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('../views/Register.vue'),
      },
      {
        path: 'forgot-password',
        name: 'ForgotPassword',
        component: () => import('../views/ForgotPassword.vue'),
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: () => import('../views/ResetPassword.vue'),
      },
    ],
  },
  {
    path: '/me',
    name: 'CurrentUser',
    component: () => import('../views/User.vue'),
    redirect: { name: 'MyAccount' },
    meta: { requiresAuth: true },
    children: [
      {
        path: 'account',
        name: 'MyAccount',
        component: () => import('../views/MyAccount.vue'),
      },
      {
        path: 'apps',
        name: 'MyApps',
        component: () => import('../views/MyApps.vue'),
      },
      {
        path: 'email/edit',
        name: 'EditEmail',
        component: () => import('../views/EditEmail.vue'),
      },
      {
        path: 'phone/edit',
        name: 'EditPhone',
        component: () => import('../views/EditPhone.vue'),
      },
      {
        path: 'password/edit',
        name: 'EditPassword',
        component: () => import('../views/EditPassword.vue'),
      },
      {
        path: '2fa/edit',
        name: 'TwoFactorAuthEdit',
        component: () => import('../views/EditTwoFactorAuth.vue'),
      },
      {
        path: 'pin',
        name: 'EditConfirmation',
        component: () => import('../views/EditConfirmation.vue'),
      },
      
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.NODE_ENV === 'development' ? '/' : process.env.VUE_APP_SUBFOLDER,
  routes,
});

export default router;
