import clientApiService from "@/services/client-api";
import router from "@/router";



export default {
  namespaced: true,
  state: {
    oauthClientName: null,
    oauthApprouveUrl: null,
    oauthCancelUrl: null,
    oauthState: null,
    oauthClientId: null,
    oauthAuthToken: null,
    oauthScopes: null,
    oauthTokenName: null,
    oauthTokenType: null,
    oauthTokenExpiresAt: null,
    oauthTokenRevoqued: null,
    oauthTwoFactorChallengeRequired: null,
    oauthSsoRedirect: null,
    user: null,
  },
  getters: {
    isAuthenticated(state){
      return (state.user && state.oauthAuthToken && !state.oauthTokenRevoqued && !state.oauthTwoFactorChallengeRequired);
    }, 
    StateUser(state){
      return state.user;
    } 
  },
  mutations: {
    SET_AUTH_CONFIG(state, payload) {
      state.oauthClientName = payload?.client_name;
      state.oauthApprouveUrl = payload?.oauthApprouveUrl;
      state.oauthCancelUrl = payload?.oauthCancelUrl;
      state.oauthState = payload?.oauthState;
      state.oauthClientId = payload?.client_id;
      state.oauthTokenName = payload?.token_unique_name;
      state.oauthTokenType = payload?.token_type;
      state.oauthTokenExpiresAt = payload?.expires_at;
      state.oauthAuthToken = payload?.access_token;
      state.oauthTokenRevoqued = payload?.revoked;
      state.oauthScopes = payload?.scopes;
      state.oauthTwoFactorChallengeRequired = payload?.two_factor_req;
      state.oauthSsoRedirect = payload?.redirect;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_TWO_FACTOR_REQUIRED(state, required) {
      this.state.oauthTwoFactorChallengeRequired = required;
    },
  },
  actions: {
    async enableTwoFactorAuth() {
      const response = await clientApiService.postEnableTwoFactorAuth();
      console.dir(response);
      return response;
    },
    async updateUserPw(_, formData) {
      const updatePwResponse = await clientApiService.updateUserPw(formData);
      return updatePwResponse;
    },
    async LogIn({ dispatch }, credentials) {
      const oauthResponse = await clientApiService.postLoginUserWithEmailAndPassword(credentials);
      dispatch('attempt', oauthResponse);
      return oauthResponse;
    },
    async LogOut({ commit }) {
        const response = await clientApiService.postLogout();
        localStorage.removeItem('oauthToken');
        commit('SET_AUTH_CONFIG', response);
        commit('SET_USER', null);
        router.push('/');      
    },
    async registerNewUser({ commit, dispatch }, payload){
      
      try {
        const registerResponse = await clientApiService.postRegisterNewUser(payload);
        commit('SET_AUTH_CONFIG', registerResponse.data);
        commit('SET_USER', registerResponse.data.user);
        dispatch('attempt', registerResponse.data);
        router.push({ name: 'CurrentUser' });
      }
      catch(err) {
        throw err.response;
      }
    },
    async attempt({ commit, state }, oauthResponse){
      if(oauthResponse){
        commit('SET_AUTH_CONFIG', oauthResponse);
      }
      if(!state.oauthAuthToken) {
        return;
      }
      
      try {
        let response = await clientApiService.getCurrentUser();

        commit('SET_USER', response.data);
      } catch (e) {
        commit('SET_USER', null);
        commit('SET_AUTH_CONFIG', {
          client_name: null,
          oauthState: null,
          client_id: null,
          token_unique_name: null,
          token_type: null,
          expires_at: null,
          access_token: null,
          revoked: null,
          scopes: null
        })
        console.log('failed', e);
      }
    }
  },
};
