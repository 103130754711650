<template>
  <div name="snackbars">
    <v-snackbar
      v-model="show"
      :color="color"
      :timeout="timeout"
      bottom
      :dark="!light"
      :light="light"
    >
      {{ text || $t('error.general') }}
      <template #action="{ attrs }">
        <v-btn
          x-small
          v-bind="attrs"
          fab
          icon
          :dark="!light"
          :light="light"
          @click="show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
const defaultState = {
  show: false,
  color: '',
  light: false,
  text: '',
  timeout: 4000,
};
export default {
  data() {
    return {
      ...defaultState,
    };
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'snackbar/SHOW_MESSAGE') {
        this.text = state.snackbar.text !== undefined ? state.snackbar.text : defaultState.text;
        this.color = state.snackbar.color !== undefined ? state.snackbar.color : defaultState.color;
        this.light = state.snackbar.light !== undefined ? state.snackbar.light : defaultState.light;
        this.timeout =
          state.snackbar.timeout !== undefined ? state.snackbar.timeout : defaultState.timeout;
        this.show = true;
      }
      if (mutation.type === 'snackbar/HIDE_MESSAGE') {
        this.show = false;
      }
    });
  },
};
</script>
