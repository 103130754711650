<template>
  <v-app>
    <router-view>
    </router-view>
    <app-snackbar></app-snackbar>
    <member-error-dialog></member-error-dialog>
    <otp-confirm></otp-confirm>
    <password-confirm></password-confirm>
    <consent-confirm></consent-confirm>
  </v-app>
</template>

<script>
import moment from 'moment';
import AppSnackbar from '@/components/AppSnackbar';
import MemberErrorDialog from '@/components/MemberErrorDialog';
import '@mdi/font/css/materialdesignicons.css';
import { mapMutations, mapActions, mapGetters, mapState } from 'vuex';
import clientApiService from '@/services/client-api';
import PasswordConfirm from '@/components/PasswordConfirm';
import OtpConfirm from '@/components/OtpConfirm';
import ConsentConfirm from '@/components/ConsentConfirm';

export default {
  name: 'App',
  props: {
    // App Config
    pageType: {
      type: String,
    },
    splashTimeout: {
      type: Number,
    },
    // Auth Config
    userId: {
      type: String,
    },
    oauthClientName: {
      type: String,
    },
    oauthApprouveUrl: {
      type: String,
    },
    oauthCancelUrl: {
      type: String,
    },
    oauthState: {
      type: String,
    },
    oauthClientId: {
      type: String,
    },
    oauthAuthToken: {
      type: String,
    },
    oauthScopes: {
      type: String,
    },
    serverErrors: {
      type: String,
    },
    registerFields: {
      type: String,
    },
    resetToken: {
      type: String,
    },
  },
  metaInfo: {
    title: 'Mon compte',
    titleTemplate: '%s | CAQ',
  },
  components: {
    AppSnackbar,
    MemberErrorDialog,
    OtpConfirm,
    PasswordConfirm,
    ConsentConfirm
  },
  mounted() {
  },
  computed: {
    ...mapState('app', ['recaptchaKey', 'user']),
    imgPath() {
      return process.env.NODE_ENV === 'development' ? '/' : process.env.VUE_APP_SUBFOLDER;
    }
  },
  watch: {
    '$i18n.locale': function(newVal) {
      localStorage.setItem('caq-locale', newVal);
      moment.locale(newVal);
      clientApiService.setLocale(localStorage.getItem('caq-locale'));
    },
    $route () {
      if(this.isAuthenticated() && this.user.initial_consent == 0) {
        this.$root.$emit('initial-consent-toggle', {open:true})
      }
    }
  },
  created() {
    this.SET_APP_CONFIG({
      pageType: this.pageType,
      splashTimeout: this.splashTimeout,
      recaptchaKey: this.recaptchaKey,
      serverErrors: this.serverErrors ? JSON.parse(atob(this.serverErrors)) : [],
      registerFields: this.registerFields ? JSON.parse(atob(this.registerFields)) : {},
      resetToken: this.resetToken,
    }, 
    this.SET_LOCALE(
      localStorage.getItem('caq-locale') || 'fr')
    );

    this.getRecaptchaKey();
    this.getLevels();
    this.getTitles();
    this.getDistricts();
    
    if (this.isAuthenticated() ) {
      if( this.$router.history._startLocation && 
          this.$router.resolve(this.$router.history._startLocation).resolved.matched.length>0) {
          
          this.$router.replace({ path: this.$router.history._startLocation });
      } else {
        this.$router.replace({ name: 'MyApps' });
      }

    } 
  },
  methods: {
    ...mapActions('app', ['getDistricts', 'getTitles', 'getLevels', 'getRecaptchaKey']),
    ...mapActions('auth', ['LogOut']),
    ...mapMutations('app', ['SET_APP_CONFIG']),
    ...mapMutations('app', ['SET_LOCALE']),
    ...mapMutations('app', ['SET_RECAPTCHA']),
    ...mapMutations('auth', ['SET_AUTH_CONFIG']),
    ...mapGetters('auth', ['isAuthenticated'])
  },
};
</script>

<style lang="scss">
.v-application .my-4 { 
  margin-bottom: 75px !important;
}

.u-font-secondary {
  font-family: "Roboto", sans-serif !important;
}

.u-background-primary-gradient {
  background: linear-gradient(162deg, var(--v-primary-base) 20%, var(--v-primary-lighten2) 100%);
}

.u-app-bar-gradient {
  background: linear-gradient(180deg, var(--v-primary-base) 0%, var(--v-primary-lighten2) 100%);
}

.container,
.v-toolbar__content,
.v-toolbar__extension {
  max-width: 700px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.v-btn--active.no-active:not(:focus):not(:hover)::before {
  opacity: 0 !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(2em);
}

footer.v-footer.v-sheet {
  background-color: #ffffff;
  text-align: center;
}

footer.v-footer.v-sheet .text--secondary {
  font-size: smaller;
}
</style>
