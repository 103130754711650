export default {
  namespaced: true,
  state: {
    text: '',
    color: '',
    timeout: '',
    light: false,
  },
  mutations: {
    SHOW_MESSAGE(state, payload) {
      state.text = payload.text;
      state.color = payload.color;
      state.light = payload.light;
      state.timeout = payload.timeout;
    },
    HIDE_MESSAGE() {},
  },
  actions: {
    showSnack({ commit }, payload) {
      commit('SHOW_MESSAGE', payload);
    },
    hideSnack({ commit }) {
      commit('HIDE_MESSAGE');
    },
  },
};
