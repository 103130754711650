import axios from 'axios';
import store from '../store/index';
import errorHandler from '@/errorHandler';
import mockFetch from './mock/mockFetch';


const MOCKING = Boolean( String(process.env.VUE_APP_MOCKING).toLowerCase() === "true" );
const DEBUG = Boolean( String(process.env.VUE_APP_DEBUG_MODE).toLowerCase() === "true" );
const DEFAULT_URL = (DEBUG) ? process.env.VUE_APP_API_URL_DEV : process.env.VUE_APP_API_URL;
if (MOCKING) {
  console.log('[MOCKING] is enabled.');
}
if(DEBUG) {
  console.warn("DEBUG MODE");
  console.log("default URL", DEFAULT_URL);
}

export const clientApiAxiosInstance = axios.create({
  baseURL: DEFAULT_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  },
});

// Intercepts response to handle errors
clientApiAxiosInstance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    errorHandler(error);
    return Promise.reject(error);
  }
);

const clientApiService = {
  setAuthHeader: (header) => {
    clientApiAxiosInstance.defaults.headers.common['Authorization'] = header;
  },
  setLocale: (locale) => {
    clientApiAxiosInstance.defaults.headers.common['X-localization'] = locale;
  },
  getCurrentUser: () => {
    if (MOCKING) {
      return mockFetch(require('./mock/data/user.json'));
    }
    return clientApiAxiosInstance.get('/api/v1/user/me');
  },
  postUserInfo: async payload => {
    //await clientApiService.getùùCookie();
    return clientApiAxiosInstance.post(`/api/v1/user/me`, payload);
  },
  getDistricts: () => {
    if (MOCKING) {
      return mockFetch(require('./mock/data/districts.json'));
    }
    return clientApiAxiosInstance.get(`/api/v1/district`);
  },
  getTitles: () => {
    if (MOCKING) {
      return mockFetch(require('./mock/data/titles.json'));
    }
    return clientApiAxiosInstance.get(`/api/v1/title`);
  },
  getLevels: () => {
    if (MOCKING) {
      return mockFetch(require('./mock/data/titles.json'));
    }
    return clientApiAxiosInstance.get(`/api/v1/level`);
  },
  postConnectionNewEmail: async newEmail => {
    const result = await clientApiAxiosInstance.post('/api/v1/pin-request/email', {
      new_email: newEmail,
    });
    store.commit('app/SET_PIN_REQUEST_ID', result.data.pin_request_api_id);
    return result.data;
  },
  postConnectionNewPhone: async newPhone => {
    const result = await clientApiAxiosInstance.post('/api/v1/pin-request/phone', {
      new_phone: newPhone,
    });
    store.commit('app/SET_PIN_REQUEST_ID', result.data.pin_request_api_id);
    return result.data;
  },
  postConnectionCheckModifyPin: async pin => {
    const result = await clientApiAxiosInstance.post('/api/v1/pin-request/check-modify-pin', {
      pin_request_api_id: store.state.app.pinRequestId,
      pin,
    });
    return result.data;
  },
  postConnectionApplyModification: async pin => {
    const result = await clientApiAxiosInstance.post('/api/v1/pin-request/apply-modification', {
      pin_request_api_id: store.state.app.pinRequestId,
      pin,
    });
    return result.data;
  },
  postPasswordEmail: async (email, recaptcha) => {
   // await clientApiService.getSanctumCookie();
    const result = await clientApiAxiosInstance.post('/password/email', {
      email,
      recaptcha,
    });
    return result.data;
  },
  getSanctumCookie: async () => {
    return clientApiAxiosInstance.get('/sanctum/csrf-cookie');
  },
  getRecaptchaPublicKey: async () => {
    //await clientApiService.getSanctumCookie();
    return clientApiAxiosInstance.get('/api/v1/recaptcha');
  },
  postPasswordReset: async (email, password, password_confirmation, recaptcha, token) => {
    //await clientApiService.getSanctumCookie();
    const result = await clientApiAxiosInstance.post('/password/reset', {
      email,
      password,
      password_confirmation,
      recaptcha,
      token
    });
    return result.data;
  },
  updateUserPw: async (payload) => {
    const result = await clientApiAxiosInstance.post('/api/v1/user/me/pw', payload);
    return result;
  },
  postRegisterNewUser: async (payload) => {
    //await clientApiService.getSanctumCookie();
    const result = await clientApiAxiosInstance.post('/api/v1/register', payload);
    return result;

  },
  postLoginUserWithEmailAndPassword: async (payload) => {
      //await clientApiService.getSanctumCookie();
      const result = await clientApiAxiosInstance.post('/api/login', payload);
      return result.data; 
  },
  postLogout: async () => {
    //await clientApiService.getSanctumCookie();
    const result = await clientApiAxiosInstance.post('/api/logout');
    return result?.data;
  },
  postEnableTwoFactorAuth: async (payload) => {
    const result = await clientApiAxiosInstance.post('/api/v1/user/two-factor-authentication', payload);
    return result;
  },
  deleteDisableTwoFactorAuth: async (payload) => {
    const result = await clientApiAxiosInstance.delete('/api/v1/user/two-factor-authentication', payload);
    return result;
  },
  putConfirmTwoFactorAuth: async () => {
    const result = await clientApiAxiosInstance.post('/api/v1/user/two-factor-authentication', {_method: "PUT"});  
    return result; // promise
  },
  putUpdateUserConsent: async (consented) => {
    const result = await clientApiAxiosInstance.post('api/v1/user/me/consent',{_method: "PUT", consented: consented});
    return result;
  },
  postTwoFactorChallenge: async (payload) => {
    return await clientApiAxiosInstance.post('api/v1/user/two-factor-challenge', payload);
  },
  getTwoFactorQrCode: async () => {
    const response = await clientApiAxiosInstance.get('/api/v1/user/two-factor-qr-code');
    return response;
  },
  getTwoFactorRecoveryCodes: async () => {
    const response = await clientApiAxiosInstance.get('api/v1/user/two-factor-recovery-codes');
    return response;
  },
  postPwChallenge: async (payload) => {
    return clientApiAxiosInstance.post('api/v1/user/me/pw-challenge', payload);
  },
  deleteDeviceLocation: async (id) => {
    return clientApiAxiosInstance.delete('api/v1/user/me/device/'+id, {})
  },
  get: async (url) => {
    return await clientApiAxiosInstance.get(url);
  },
  post: async (url, data) => {
    return await clientApiAxiosInstance.post(url, data);
  }
};

export default clientApiService;
