import { extend, configure, setInteractionMode } from 'vee-validate';
import {
  required,
  email,
  digits,
  min_value,
  max_value,
  numeric,
  min,
  max,
} from 'vee-validate/dist/rules';
import i18n from '../i18n';

// Interaction Mode
setInteractionMode('eager');

// Rules
extend('digits', digits);
extend('numeric', numeric);
extend('min_value', min_value);
extend('max_value', max_value);
extend('min', min);
extend('max', max);
extend('required', required);
extend('email', email);
extend('phone', {
  validate(value) {
    const regexInt = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    const regexLocal = /^[0-9]{10}$/;
    const sanitizeValue = value
      .replaceAll('.', '')
      .replaceAll('-', '')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll(' ', '');

    return sanitizeValue.match(regexInt) ? true : sanitizeValue.match(regexLocal);
  },
  message: () => i18n.t('customValidations.messages.phone'),
});
extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: () => i18n.t('customValidations.messages.password'),
});
extend('postal_code', {
  validate(value) {
    const regex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
    return value.match(regex);
  },
  message: () => i18n.t('customValidations.messages.postal_code'),
});

// Error messages
configure({
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`fields.${field}`);
    return i18n.t(`validations.messages.${values._rule_}`, values);
  },
});
