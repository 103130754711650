import Vue from 'vue';
import App from './App.vue';
import i18n from './i18n';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/vee-validate';
import moment from 'moment';
import axios from 'axios';
import './registerServiceWorker'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
require('@/store/subscriber');
import { VueReCaptcha } from 'vue-recaptcha-v3';


Vue.config.productionTip = true;

const recaptchaOptions = {
  loaderOptions: {
    autoHideBadge: true,
  },
};

if (store.state.app.recaptchaKey) {
  Vue.use(VueReCaptcha, {
    siteKey: store.state.app.recaptchaKey,
    ...recaptchaOptions,
  });
} else {
  store.dispatch('app/getRecaptchaKey').then(() => {
    Vue.use(VueReCaptcha, {
      siteKey: store.state.app.recaptchaKey,
      ...recaptchaOptions,
    });
  });
}

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
store.dispatch('auth/attempt', JSON.parse(localStorage.getItem('oauthToken')));
axios.interceptors.response.use(undefined, function (error){
  if(error){
    const originalRequest = error.config;
    if(error.response.status === 401 && !originalRequest._retry){
      originalRequest._retry = true;
      store.dispatch('LogOut');
      return router.push('/login');
    }
  }
});

App.store = store;
App.router = router;
App.vuetify = vuetify;
App.i18n = i18n;

moment.locale(i18n.locale);

// if (process.env.NODE_ENV !== 'development') {
//   // Silent logs in production, only keep errors
//   window.console.log = () => {};
//   window.console.info = () => {};
//   window.console.warn = () => {};
// }

//const rootEl = document.querySelector('#app');

new Vue({
  components: { App },
  render: createElement => {
    const context = {
      props: {
        pageType: 'LoginSelector',
        splashTimeout: 1000,
      },
    };
    return createElement(App, context);
  },
  store,
  router,
  vuetify
}).$mount('#app');


