<template>
  <v-dialog
    v-model="dialog"
    width="380"
    persistent
  >
    <v-card>
      <v-card-title>{{ $t('error.memberError.title') }}</v-card-title>
      <v-card-text>
        {{ $t('error.memberError.description') }}
        <v-list
          dense
          class="mt-3"
        >
          <v-list-item href="tel:5148006000">
            <v-list-item-icon>
              <v-icon small>
                mdi-phone
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>514 800-6000 (Montréal)</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item href="tel:4188006000">
            <v-list-item-icon>
              <v-icon small>
                mdi-phone
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>418 800-6000 (Québec)</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item href="tel:18664162960">
            <v-list-item-icon>
              <v-icon small>
                mdi-phone
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>1 866 416-2960 (Sans frais)</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon small>
                mdi-fax
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>514 800-0081</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item href="mailto:info@coalitionavenirquebec.org">
            <v-list-item-icon>
              <v-icon small>
                mdi-email
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>info@coalitionavenirquebec.org</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="dialog = false"
        >
          {{ $t('error.memberError.action') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'app/dialogMemberError') {
        this.dialog = state.app.dialogMemberError;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__text,
.v-dialog > .v-card > .v-card__title {
  padding: 8px;
}

.v-list-item {
  padding: 0 8px;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 6px;
}
</style>
