<template>
    <v-dialog
        v-model="isOpen"
        max-width="600px"
        persistent
    >
    <v-card>
        <v-card-title>
            <span class="text-h5" v-html="$t('consentDialog.title')"></span>
        </v-card-title>
        <v-card-text v-html="$t('consentDialog.body')">
   
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="onDeclineConsentClick"
          >
            {{ $t('consentDialog.btnDisagreeLabel') }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="onAcceptConsentClick"
          >
            {{ $t('consentDialog.btnAgreeLabel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    
</template>

<script>
import { mapActions } from 'vuex';


export default {
    name: "consent-confirm",
    components: {

    },
    computed: {
        
    },
    props: {
        open: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            isOpen: ''
        }
    },
    created() {
        this.$root.$on('initial-consent-toggle', this.onToggleInitialConsentDialog)
    },
    methods: {
        ...mapActions('auth', ['LogOut']),
        ...mapActions('app', ['updateUserConsent']),
        onToggleInitialConsentDialog(payload) {
            if(payload.open) {
                this.isOpen = payload.open;
            }
        },
        async onDeclineConsentClick() {
            this.LogOut().then(() => {
                this.isOpen = false;
            });
        },
        async onAcceptConsentClick() {
            this.updateUserConsent(true).then(() => {
                this.isOpen = false;
            });
        }
    },
    mounted() {
        this.isOpen = this.open;
    },
    watch: {

    }
};
</script>