import i18n from '@/i18n';
import store from '@/store/index';
import router from '@/router/index';

function getErrorMessage(error) {
  switch (error.response.status) {
    case 400:
      if (Array.isArray(error.response.data.errors)) {
        let errorMsg;
        // Concat all error message
        error.response.data.errors.map(e => {
          errorMsg = e.message + ' ';
        });
        return errorMsg;
      } else {
        return error.response.data.errors.message;
      }
    default:
      return i18n.t('error.general');
  }
}

export default function errorHandler(error) {
  if (error && error.response && error.response.status) {
    if (error.response.status === 400) {
      console.log('[ErrorHandler] Handle 400');
      if (error.response.data.errors && error.response.data.errors.code === 'MEMBER_ERROR') {
        store.dispatch('app/showMemberErrorDialog');
      } else {
        const message = getErrorMessage(error);
        store.dispatch('snackbar/showSnack', {
          text: message,
          color: 'error',
          timeout: -1,
        });
      }
    }
    else if (error.response.status === 401 && error.response.data.message === 'Unauthenticated.') {
      console.log('[ErrorHandler] Handle 401');
   
        router.replace({ name: 'LoginSelector' }).catch(error => {
          if (error.name !== 'NavigationDuplicated') {
            console.warn(error);
          }
        });
        store.dispatch('snackbar/showSnack', {
          text: i18n.t('error.401.invalidToken'),
          color: 'error',
          timeout: 8000,
        });
    
    }
    else if (error.response.status === 500) {
      console.log('[ErrorHandler] Handle 500');
      const message = getErrorMessage(error);
      store.dispatch('snackbar/showSnack', {
        text: message,
        color: 'error',
        timeout: -1,
      });
    }
    else {
      // Sebastien Pilon
      // lacaq 10-03-2022
      // In case we have an error response but we dont have overridden the message from the back, we will display it as is, 
      // the backend has a default locale that can be set from the front, 
      // will be implemented later 
      console.log('[ErrorHandler] Fallback');
      store.dispatch('app/setServerErrors', error.response.data.errors);
      const messagesObj = error.response.data?.errors || [];
      const messages = [];
      for(let prop in messagesObj) {
          messages.push(messagesObj[prop]);
      }
      store.dispatch('snackbar/showSnack', {
        text: messages.toString(),
        color: 'error',
        timeout: 8000,
      });
    }
  } else {
    // API is most likely down
    console.log('[ErrorHandler] Fallback');
    store.dispatch('snackbar/showSnack', {
      text: i18n.t('error.general'),
      color: 'error',
      timeout: 8000,
    });
  }
}
