import store from '@/store';
import clientApiService from '@/services/client-api';

store.subscribe((mutation) => {
    //
    switch(mutation.type) {
        case 'auth/SET_AUTH_CONFIG':
            if(mutation.payload && mutation.payload.access_token && !mutation.payload.revoked) {
                clientApiService.setAuthHeader(mutation.payload.token_type + " " + mutation.payload.access_token); 
                localStorage.setItem('oauthToken', JSON.stringify(mutation.payload));
            } else {
                clientApiService.setAuthHeader(null);
                localStorage.removeItem('oauthToken');
            }
            break;
    }
});