<template>
    <v-dialog
      v-model="isOpen"
      persistent
      max-width="400">
      <v-card>
         <v-card-title class="text-subtitle-1" v-html="otpTitle"></v-card-title>
        <v-spacer></v-spacer>
        <v-card-subtitle v-html="otpBody"></v-card-subtitle>
        <v-card-text>
            <validation-observer ref="formOtpConfirmObserver" tag="form">
                <v-form
                    id="otpForm"
                    novalidate>
                    <validation-provider
                        v-slot="{}"
                        vid="code"
                        rules="required"> 
                        <v-otp-input
                            v-if="!recoveryMode"
                            outlined
                            :required="!recoveryMode"
                            name="code"
                            ref="otp_code"
                            type="number"
                            label="Code"
                            v-model="otp"
                            :length="otpLength"
                            :disabled="otpDisabled"
                            @finish="onFinish">
                        </v-otp-input>
                           <v-text-field
                           v-else
                            :required="recoveryMode"
                            append-icon="mdi-send-circle-outline"
                            @click:append="onFinish"
                            label="Code de récupération"
                            v-model="recovery_code"
                            :disabled="otpDisabled"
                            outlined
                        >
                
                        </v-text-field>
                        <div class="v-text-field__details" v-if="otpErrors[0]">
                            <div class="v-messages theme--light error--text" role="alert">
                                <div class="v-messages__wrapper">
                                    <div class="v-messages__message">
                                        {{ otpErrors[0] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                         <div class="v-text-field__details" v-if="!recoveryMode">
                           <a @click="recoveryMode=true">Code de récupération ?</a>
                        </div>
                        <div class="v-text-field__details" v-else>
                           <a @click="recoveryMode=false">Retour</a>
                        </div>
                    </validation-provider>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="mt-2"
                        small
                        color="error"
                        @click="onOtpCloseClick"
                        dark>
                        {{ $t('twoFactorDialog.btnCancelLabel') }}
                    </v-btn>
                </v-form>
            </validation-observer>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-dialog
            v-model="validating"
            hide-overlay
            persistent
            width="300">
            <v-card
                color="primary"
                dark>
                <v-card-text>
                    {{ $t('twoFactorDialog.validationInProgress') }}
                    <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0">
                    </v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
      </v-card>
    </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import clientApiService from '@/services/client-api';

export default {
    name: 'otp-confirm',
    components: {
        ValidationObserver,
        ValidationProvider
    },
    computed: {
        validForm() {
            return (this.otpAttempt < 3);
        }
    },
    props: {
        length: {
            default: 6,
            type: Number
        }, 
        title: {
            default: '',
            type: String
        }, 
        body: {
            default: '',
            type: String
        }, 
        open: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            action: {},
            otpLength: 6,
            isOpen: this.open,
            otpTitle: this.title,
            otpBody: this.body,
            otp: '',
            recovery_code: '',
            validating: false,
            otpDisabled: false,
            otpAttempt: 0,
            otpErrors: [],
            recoveryMode: false
        }
    },
    created() {
        this.$root.$on('otp-confirm-toggle', this.onOtpToggleDialog); 
    },
    methods: {
        onFinish() {
            if(this.validForm) {
                this.otpDisabled = true;
                this.validating = true;
            }
        },
        onOtpToggleDialog(payload) {
            this.otp = '';
            this.action = payload.action;
            this.isOpen = (payload.open) ? payload.open : !this.isOpen;
        },
        focusOtp() {
            this.$nextTick(() => {
                if(this.$refs.otp_code && this.$refs.otp_code.$refs.input[0] && this.isOpen) {
                    this.$refs.otp_code.$refs.input[0].focus();
                }
            });
        },
        resetOtp() {
            this.validating = false;
            this.otpDisabled = false;
            this.otp = '';
            this.recovery_code = '';
            this.focusOtp();
        },
        setOtpErrors(errors) {
            // vuetify doesn't support error reporting by default, setErrors does not work for backend server errors, so I couldn't use 
            // a v-slot errors... it does the same thing anyws, 
            // instead of this.$refs.formObserver.setErrors({}); simply use this.setOtpErrors({})
            this.otpErrors = errors?.code ?? [];
        },
        onOtpCloseClick() {
            this.otpAttempt = 0;
            this.otp = '';
            this.isOpen = false;
            this.$root.$emit('otp-failed');
        },
    },
    mounted() {
        this.otpTitle   =   (this.title) ? this.title : this.$t('twoFactorDialog.title');
        this.otpBody    =   (this.body) ? this.body : this.$t('twoFactorDialog.body');
        this.otpLength  =   this.length;
        this.focusOtp();
    },
    watch: {
      validating (val) {
        if (!val) return
        this.otpAttempt++;
            if(this.validForm) {
                this.setOtpErrors({});
                clientApiService.postTwoFactorChallenge({code:this.otp.toString(), recovery_code: this.recovery_code.toString()})
                .then(({ data }) => {
                    if(data.success) {
                        this.$root.$emit('otp-confirmed', data, this.action.node[this.action.fn]);
                        this.otp = '';
                        this.recovery_code ='';
                        this.otpAttempt = 0;
                        this.otpDisabled = false;
                        this.validating = false;
                        this.isOpen = false;
                    }
                })
                .catch(()=>{
                    this.otpDisabled = false;
                    this.validating = false;
                    this.resetOtp();
                    this.setOtpErrors(this.serverErrors);
                })
            } else {
                this.setOtpErrors({code:[this.$t('twoFactorDialog.tooManyAttempts')]});
                this.validating = false;
                this.otpDisabled = true;
                this.$root.$emit('otp-failed');
            }
        }, //validating
        isOpen (val) {
            if(val) {
                this.focusOtp();
            }
        }//isOpen
    }
};
</script>

