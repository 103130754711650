<template>
    <v-dialog
      v-model="isOpen"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-subtitle-1" v-html="passwordConfirmTitle"></v-card-title>
        <v-spacer></v-spacer>
        <v-card-subtitle v-html="passwordConfirmBody"></v-card-subtitle>
        <v-card-text>
          <validation-observer ref="formPasswordConfirmObserver" tag="form">
            <v-form
              id="passwordConfirmForm"
              novalidate
              @submit.prevent="onValidatePassword"
            >
              <validation-provider
                v-slot="{ errors, invalid }"
                vid="password"
                rules="required" 
              >
                <v-text-field 
                  dense
                  v-model="password"
                  ref="password"
                  type="password"
                  name="password"
                  :error-messages="errors"
                  :label="$t('passwordConfirmDialog.passwordLabel')"
                  :placeholder="$t('passwordConfirmDialog.passwordPlaceholder')"
                  :disabled="disabled         "
                  outlined
                  required
                >
                </v-text-field>
                <v-btn
                  v-if="validForm"
                  small
                  color="primary"
                  block
                  :loading="validating"
                  :disabled="disabled || invalid || !validForm"
                  @click="onValidatePassword"
                >
                  {{ $t('passwordConfirmDialog.btnValidateLabel') }}
                </v-btn>
                <v-btn
                  v-else
                  class="mt-2"
                  small
                  color="error"
                  @click="onCloseClick"
                  dark>
                  {{ $t('passwordConfirmDialog.btnCancelLabel') }}
                </v-btn>
              </validation-provider>  
              <v-spacer></v-spacer>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapState, mapActions } from 'vuex';
import clientApiService from '@/services/client-api';

export default {
    name: 'password-confirm',
    components: {
      ValidationObserver, 
      ValidationProvider
    },
    computed: {
       ...mapState('app', ['serverErrors']),
      validForm() {
        return (this.attempts < 3);
      }
    },
    props: {
      title: {
        default: '',
        type: String
      }, 
      body: {
        default: '',
        type: String
      }, 
      open: {
        default: false,
        type: Boolean
      }
    },
    data() {
        return {
            isOpen: this.open,
            passwordConfirmTitle: this.title,
            passwordConfirmBody: this.body,
            password: '',
            disabled: false,
            attempts: 0,
            pwErrors: [],
            validating: false,
            action: null,
        }
    },
    created() {
      this.$root.$on('password-confirm-toggle', this.onPasswordConfirmToggleDialog);
    },
    methods: {
       ...mapActions('app', ['setServerErrors']),
      async onPasswordConfirmToggleDialog(payload) {
          this.password = '';
          this.isOpen = (payload.open) ? payload.open : !this.isOpen;
          this.action = payload.action;
      },
      onCloseClick() {
        this.attempts = 0;
        this.password = '';
        this.isOpen = false;

      },
      async onValidatePassword() {
            if(this.validForm) {
                this.disabled = true;
                this.validating = true;
            }
      },
      resetPassword() {
        this.validating = false;
        this.disabled = false;
        this.password = '';
        this.focusPassword();
      },
      focusPassword() {
        this.$nextTick(() => {
          if(this.$refs.password && this.isOpen) {
            this.$refs.password.focus();
          }
        });
      }
    },
    mounted() {
      this.passwordConfirmTitle = (this.title)  ? this.title  : this.$t('passwordConfirmDialog.title');
      this.passwordConfirmBody  = (this.body)   ? this.body   : this.$t('passwordConfirmDialog.body');
      this.focusPassword();
    },
    watch: {
      async validating (val) {
        if (!val) return
          this.attempts++;
          const recaptchaToken = await this.$recaptcha('password/challenge');
          if(this.validForm) {
            clientApiService.postPwChallenge({password: this.password, recaptcha: recaptchaToken})
            .then(({ data }) =>{
              this.disabled = false;
              this.validating = false;
              this.$refs.formPasswordConfirmObserver.setErrors({});
              this.$root.$emit('password-confirmed', data, this.action.node[this.action.fn]);   
              this.password = '';
              this.isOpen = false;   
            })
            .catch(() => {
              this.disabled = false;
              this.validating = false;
              this.$refs.formPasswordConfirmObserver.setErrors(this.serverErrors);
            });
          } else {
            this.disabled = true;
            this.validating = false;
            this.$root.$emit('password-failed');
            this.$refs.formPasswordConfirmObserver.setErrors({password:[this.$t('twoFactorDialog.tooManyAttempts')]});
          }     
      }, //validatingPassword
      isOpen (val) {
        if(val) {
          this.focusPassword();
        }
      }, //isOpen
      async password (val) {
        if(val === '' || val.length === 1 && this.validForm) {
          await this.$refs.formPasswordConfirmObserver.validate();
        }
      } //password
    }
};
</script>

