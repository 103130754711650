import clientApiService from '@/services/client-api';

export default {
  namespaced: true,
  state: {
    pageType: 'LoginSelector',
    splashTimeout: 3000,
    recaptchaKey: '',
    serverErrors: {},
    registerFields: {},
    backRoute: '/auth',
    resetToken: '',
    locale: 'fr',
    //
    user: null,
    //
    districts: [],
    titles: [],
    levels: [],
    //
    connectType: '',
    connectInfo: '',
    pinRequestId: null,
  },
  getters: {
    GoogleRecaptchaKey(state){
      return state.recaptchaKey;
    } 
  },
  mutations: {
    SET_SERVER_ERRORS(state,payload) {
      state.serverErrors = payload;
    },
    SET_RECAPTCHA_KEY(state, key){
      state.recaptchaKey = key;
    },
    SET_LOCALE(state, locale){
      state.locale = locale;
      clientApiService.setLocale(locale);
    },
    SET_APP_CONFIG(state, payload) {
      state.pageType = payload.pageType;
      state.splashTimeout = payload.splashTimeout;
      state.serverErrors = payload.serverErrors;
      state.registerFields = payload.registerFields;
      state.resetToken = payload.resetToken;
    },
    SET_CONNECT_TYPE(state, connectType) {
      state.connectType = connectType;
    },
    SET_CONNECT_INFO(state, connectInfo) {
      state.connectInfo = connectInfo;
    },
    SET_BACKROUTE(state, backRoute) {
      state.backRoute = backRoute;
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_PIN_REQUEST_ID(state, id) {
      state.pinRequestId = id;
    },
    SET_DISTRICTS(state, payload) {
      state.districts = payload;
    },
    SET_TITLES(state, payload) {
      state.titles = payload;
    },
    SET_LEVELS(state, payload) {
      state.levels = payload;
    },
    SET_USER_2FA(state, payload) {
      state.user.two_factor = payload.confirmed;
      state.user.two_factor_status = payload.enabled;
      if(payload.two_factor_status) {
        state.user.two_factor_status = payload.two_factor_status;
      }
    },
    SET_USER_2FA_QR(state, payload) {
      state.user.two_factor_qr = payload;
    },
    SET_USER_CONSENT(state, consented) {
      state.user.initial_consent = (consented) ? 1 : 0;
    },
  },
  actions: {
    async getTwoAuthQr({ commit }) {
      const { data } = await clientApiService.getTwoFactorQrCode();
      commit('SET_USER_2FA_QR', data.svg);
      return data.svg;
    },  
    async getCurrentUser({ commit }) {
      const { data } = await clientApiService.getCurrentUser();
      console.dir( data );
      commit('SET_USER', data);
    },
    async saveUser(_, payload) {
      const { data } = await clientApiService.postUserInfo(payload);
      console.dir(data);
    },
    async getDistricts({ commit }) {
      const { data } = await clientApiService.getDistricts();
      commit('SET_DISTRICTS', data.data);
    },
    async getTitles({ commit }) {
      const { data } = await clientApiService.getTitles();
      commit('SET_TITLES', data.data);
    },
    async getLevels({ commit }) {
      const { data } = await clientApiService.getLevels();
      commit('SET_LEVELS', data.data);
    },
    async getRecaptchaKey({ commit }) {
      const { data } = await clientApiService.getRecaptchaPublicKey();
      commit('SET_RECAPTCHA_KEY', data.key);
    },
    setServerErrors({ commit }, payload) {
      commit('SET_SERVER_ERRORS', payload);
    },
    async setUserTwoFactor({ commit }, payload) {
      const { data } = await clientApiService.postEnableTwoFactorAuth(payload);
      commit('SET_USER_2FA', data);
    },
    async confirmTwoFactor({ commit }) {
      const { data } = await clientApiService.putConfirmTwoFactorAuth();
      commit('SET_USER_2FA', data);    
    },
    async setDisableTwoFactor({ commit }, payload) {
      const { data } = await clientApiService.deleteDisableTwoFactorAuth(payload);
      commit('SET_USER_2FA', data);
    },
    async deleteDeviceLocation(_, payload) {
      const { data } = await clientApiService.deleteDeviceLocation(payload);
      return data;
    },
    async updateUserConsent({ commit }, consented) {
      const { data } = await clientApiService.putUpdateUserConsent(consented);
      commit('SET_USER_CONSENT', data.consented);
    },
  },
};
